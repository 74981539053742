import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import {
  CallRecords,
  DashboardIcon,
  DID,
  DisplayGroups,
  HashIcon,
  LiveBoards,
  MyXToneIcon,
  Provision,
  Reports,
  SmsIcon,
  TimeZoneRestriction,
  ToolsIcon,
} from "../utils/icons/defaultIcons";
import ScrubTool from "../containers/tools/scrubTool";
// import PlanIcon from '../utils/icons/PlanIcon';
// import QualityIcon from '../utils/icons/QualityIcon';
// import PortfolioIcon from '../utils/icons/PortfolioIcon';
// import ReportIcon from '../utils/icons/ReportIcon';
// import FilesIcon from '../utils/icons/FilesIcon';
// import ResourceUtilizationIcon from '../utils/icons/ResourceUtilizationIcon';

export const LeftMenuItems = {
  control: [
    {
      item: "dashboard",
      path: "dashboard",
      displayName: "Dashboard",
      hidden: true,
      icon: <DashboardIcon />,
      permission: "",
    },
    {
      item: "my_xtone",
      path: "myxtone",
      displayName: "My XTone",
      icon: <MyXToneIcon />,
      permission: "live_boards.live_boards.queue_monitor.get",
    },
    {
      item: "call_recordings",
      path: "recordings",
      displayName: "Recordings",
      icon: <CallRecords />,
      permission: "",
      childrens: [
        {
          item: "call_recordings",
          path: "callrecordings",
          displayName: "Call Recordings",
          permission: "call_recordings.call_archives.get",
        },
      ],
    },
    {
      item: "timeZoneRestriction",
      path: "timeZoneRestriction",
      displayName: "Time Zone Restriction",
      icon: <TimeZoneRestriction />,
      permission: "",
    },

    {
      item: "live_boards",
      path: "liveboards",
      displayName: "Live Boards",
      icon: <LiveBoards />,
      permission: "",
      childrens: [
        // {
        //   item: "queue_monitor",
        //   path: "queuemonitor",
        //   displayName: "Queue Monitor",
        //   permission: "live_boards.queue_monitor.get",
        // },

        {
          item: "user_panel",
          path: "userpanel",
          displayName: "User Panel",
          permission: "live_boards.user_panel.get",
        },
        {
          item: "calls_in_progress",
          path: "activecalls",
          displayName: "Active Calls",
          permission: "live_boards.calls_in_progress.get",
        },
        {
          item: "wallboard",
          path: "wallboard",
          displayName: "Wallboard",
          permission: "live_boards.wallboard.get",
        },

        // {
        //   item: "call_recordings",
        //   path: "manageExtension",
        //   displayName: "Manage Extension",
        //   permission: "call_recordings.manage_extension.get",
        // },
      ],
    },

    {
      item: "sms",
      path: "sms",
      displayName: "SMS",
      icon: <SmsIcon />,
      permission: "",
      childrens: [
        {
          item: "smsusers",
          path: "smsusers",
          displayName: "SMS Users",
          permission: "sms.sms_users.get",
        },
        // {
        //   item: "provisioning",
        //   path: "smsGroups",
        //   displayName: "SMS Groups",
        //   permission: "provision_tenants.get",
        // },
      ],
    },

    {
      item: "provision",
      path: "provision",
      displayName: "Provision",
      icon: <Provision />,
      permission: "",
      childrens: [
        {
          item: "provisioning",
          path: "tenants",
          displayName: "Tenants",
          permission: "provision_tenants.get",
        },
      ],
    },

    {
      item: "dids",
      path: "dids",
      displayName: "DIDs",
      icon: <DID />,
      permission: "dids.get",
    },
    {
      item: "dcid",
      path: "dcid",
      displayName: "DCID",
      icon: <HashIcon />,
      permission: "dcid.get",
    },

    {
      item: "reports",
      path: "reports",
      displayName: "Reports",
      icon: <Reports />,
      permission: "",
      childrens: [
        {
          item: "callReports",
          path: "callReports",
          displayName: "Call Reports",
          permission: "reports.call_report.get",
        },
        {
          item: "callStatistics",
          path: "callStatistics",
          displayName: "Call Statistics",
          permission: "reports.call_statistics.get",
        },
        {
          item: "aboutReporting",
          path: "aboutReporting",
          displayName: "About Reporting",
          permission: "provision_tenants.get",
          // reports.about_reporting.get
        },
        {
          item: "displayGroups",
          path: "displayGroups",
          displayName: "Display Groups",
          permission: "provision_tenants.get",
          // reports.display_groups.get
        },
      ],
    },

    {
      item: "tools",
      path: "tools",
      displayName: "Tools",
      icon: <ToolsIcon />,
      permission: "",
      childrens: [
        {
          item: "scrubtool",
          path: "scrubtool",
          displayName: "Scrub Tool",
          permission: "tools.scrub_tool.get",
        },
        {
          item: "webhooks",
          path: "webhooks",
          displayName: "Webhooks",
          permission: "tools.webhooks.get",
        },
      ],
    },
  ],
};
