import { useEffect, useRef, useState } from "react";
import {
  CloseIcon,
  DragIcon,
  DragIcon2,
  EyeIcon,
  TableSortIconDownArrow,
} from "../../../utils/icons/defaultIcons";
import FadeIn from "react-fade-in";
import ComponentMap from "../../../atom/componentmap";
import Table from "../../../atom/table";
import { getENVData } from "../../../config/environment";
import { getToken } from "../../../services/api";
import { useSelector } from "react-redux";
import { secondsToHHMMSS } from "../../../services/utilities";
import { Backdrop } from "@mui/material";
import Lottiefy from "../../../atom/lottie/lottie";
import * as loaderLottie from "../../../utils/lottie/loaderLottie.json";

function Wallboard() {
  const modalRef = useRef<HTMLInputElement>(null);
  const [hideDropdown, setHideDropdown] = useState<boolean>(false);
  const [incStatsData, setIncStatsData] = useState<any>({});
  const [outStatsData, setOutStatsData] = useState<any>({});

  const isComponentMounted = useRef(false); // To track if the component is mounted
  const socketRef = useRef<WebSocket | null>(null);
  const accessToken = useSelector((state: any) => state.auth.token);
  const [loader, setLoader] = useState<boolean>(false);
  const usersTableTitles = [
    { key: "extension", title: "Extension", icon: <TableSortIconDownArrow /> },
    {
      key: "name",
      title: "Name",
      icon: <TableSortIconDownArrow />,
    },
    {
      key: "calls_received",
      title: "Call Received",
      icon: <TableSortIconDownArrow />,
    },
    { key: "calls_made", title: "Call Made", icon: <TableSortIconDownArrow /> },
    {
      key: "internal_call",
      title: "Internal Call",
      icon: <TableSortIconDownArrow />,
    },
    {
      key: "talk_time",
      title: "Talk Time",
      icon: <TableSortIconDownArrow />,
    },
    {
      key: "average_talk_time",
      title: "Average Talk Time",
      icon: <TableSortIconDownArrow />,
    },
    {
      key: "status",
      title: "Status",
      type: "currentStatus",
      class: "text-center",
      icon: <TableSortIconDownArrow />,
    },
  ];

  const [usersTableData, setUsersTableData] = useState<any>([]);

  const tableTitles2 = [
    { key: "data1", title: "Extension", icon: <TableSortIconDownArrow /> },
    {
      key: "data2",
      title: "First Name",
      icon: <TableSortIconDownArrow />,
    },
    {
      key: "data3",
      title: "Last Name",
      icon: <TableSortIconDownArrow />,
    },
    {
      key: "data4",
      title: "Status",
      icon: <TableSortIconDownArrow />,
    },
  ];

  const incStatisticsTableTitles = [
    { key: "name", title: "", icon: <TableSortIconDownArrow /> },
    {
      key: "total_talk_time",
      title: "Total",
      icon: <TableSortIconDownArrow />,
    },
    {
      key: "avg_talk_time",
      title: "Average",
      icon: <TableSortIconDownArrow />,
    },
    {
      key: "max_talk_time",
      title: "Maximum",
      icon: <TableSortIconDownArrow />,
    },
  ];

  const [incStatisticsTableData, setIncStatisticsTableData] = useState<any>([]);

  const outStatisticsTableTitles = [
    { key: "name", title: "", icon: <TableSortIconDownArrow /> },
    {
      key: "total_talk_time",
      title: "Total",
      icon: <TableSortIconDownArrow />,
    },
    {
      key: "avg_talk_time",
      title: "Average",
      icon: <TableSortIconDownArrow />,
    },
    {
      key: "max_talk_time",
      title: "Maximum",
      icon: <TableSortIconDownArrow />,
    },
  ];

  const [outStatisticsTableData, setOutStatisticsTableData] = useState<any>([]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (modalRef.current && !modalRef.current.contains!(event.target)) {
        setHideDropdown(false);
      }
    };

    // Attach the event listener to the document
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [hideDropdown]);

  const [hideDropdownValues, setHideDropdownValues] = useState<any>([
    { name: "Direction", checked: false },
    { name: "Orginal Extension", checked: false },
    { name: "Transfer Extension", checked: false },
    { name: "Groups", checked: false },
    { name: "Call Organization", checked: false },
    { name: "Call Destination", checked: false },
    { name: "Start Time", checked: false },
    { name: "End Time", checked: false },
    { name: "Total Time", checked: false },
  ]);

  const [wallBoardData, setWallBoardData] = useState<any>([
    {
      title: "Last Hours",
      name: "lastHours",
      value: "",
      type: "dropdown",
      displayProps: { label: "label", value: "uuid" },
      childrens: [
        { label: "Last hour", value: "Last hour" },
        { label: "Last 2 hours", value: "Last 2 hours" },
      ],
    },
  ]);

  const objectsToArray = (data: any, item: string) => {
    if (item === "incoming") {
      console.log("checkdata", data);
      const wait = {
        name: "Wait",
        total_talk_time: secondsToHHMMSS(data?.total_wait_time),
        avg_talk_time: secondsToHHMMSS(data?.avg_wait_time),
        max_talk_time: secondsToHHMMSS(data?.max_wait_time),
      };
      const talk = {
        name: "Talk",
        total_talk_time: secondsToHHMMSS(data?.total_talk_time),
        avg_talk_time: secondsToHHMMSS(data?.avg_talk_time),
        max_talk_time: secondsToHHMMSS(data?.max_talk_time),
      };

      setIncStatisticsTableData([wait, talk]);
      console.log("compile", [wait, talk]);
    }

    if (item === "outgoing") {
      const wait = {
        // secondsToHHMMSS
        name: "Wait",
        total_talk_time: secondsToHHMMSS(data?.total_wait_time),
        avg_talk_time: secondsToHHMMSS(data?.avg_wait_time),
        max_talk_time: secondsToHHMMSS(data?.max_wait_time),
      };
      const talk = {
        name: "Talk",
        total_talk_time: secondsToHHMMSS(data?.total_talk_time),
        avg_talk_time: secondsToHHMMSS(data?.avg_talk_time),
        max_talk_time: secondsToHHMMSS(data?.max_talk_time),
      };

      setOutStatisticsTableData([wait, talk]);
    }
  };

  const connectWebSocket = () => {
    const wssUrl = getENVData().baseURL.replace("https", "wss");

    const url = `${wssUrl}events/liveboard/tenants/${getToken()}/ws/wallboard/${24}?token=${accessToken}`;
    if (!socketRef.current) {
      socketRef.current = new WebSocket(url);
      setLoader(true);
      socketRef.current.addEventListener("open", (event) => {
        console.log("Connected to WebSocket", event);
        setLoader(false);
      });

      socketRef.current.addEventListener("message", (event) => {
        console.log("Message received from server:", event);
        const data = JSON.parse(event.data);

        setLoader(false);

        // Statistics
        if (
          data?.event === "incoming_statistics.create" ||
          data?.event === "incoming_statistics.update"
        ) {
          console.log("data", data);
          setIncStatsData(data);
          objectsToArray(data, "incoming");
        }
        if (
          data?.event === "outgoing_statistics.create" ||
          data?.event === "outgoing_statistics.update"
        ) {
          setOutStatsData(data);
          objectsToArray(data, "outgoing");
        }

        // Users
        if (data?.event === "users.create") {
          data.talk_time = secondsToHHMMSS(data?.talk_time);
          data.average_talk_time = secondsToHHMMSS(data?.average_talk_time);
          data.status = data.status === "idle" ? "Available" : "Busy";
          setUsersTableData((prevEvents: any) => [...prevEvents, data]);
        }
        if (data?.event === "users.update") {
          data.talk_time = secondsToHHMMSS(data?.talk_time);
          data.average_talk_time = secondsToHHMMSS(data?.average_talk_time);
          data.status = data.status === "idle" ? "Available" : "Busy";
          setUsersTableData((prevEvents: any) =>
            prevEvents.map((event: any) =>
              event.uuid === data.uuid ? { ...event, ...data } : event
            )
          );
        }
      });

      socketRef.current.addEventListener("close", (e: Event) => {
        setLoader(false);
        if (isComponentMounted.current) {
          console.log("WebSocket connection closed", socketRef.current, e);
          console.log("WebSocket connection closed Attempting to reconnect...");
          socketRef.current = null;
          reconnectWebSocket();
        } else {
          console.log(
            "WebSocket closed and no reconnect as component is unmounted."
          );
        }
      });
      socketRef.current.addEventListener("error", (error) => {
        setLoader(false);
        console.error("WebSocket encountered an error:", error);
        if (socketRef.current) {
          socketRef.current.close(1000, "Normal closure"); // Close the connection on error
        }
      });
    }
  };

  const reconnectWebSocket = () => {
    if (!isComponentMounted.current) return; // Stop reconnecting if the component is unmounted
    setTimeout(() => {
      console.log("Reconnecting WebSocket...");
      connectWebSocket(); // Retry connection
    }, 3000); // Retry after 3 seconds
  };

  useEffect(() => {
    isComponentMounted.current = true; // Component is mounted
    if (socketRef.current) {
      socketRef.current.close(1000, "Normal closure");
      console.log("WebSocket closed during cleanup");
    }
    setTimeout(connectWebSocket, 100);
    return () => {
      isComponentMounted.current = false; // Component is mounted
      if (socketRef.current) {
        socketRef.current.close(1000, "Normal closure");
        console.log("WebSocket closed during cleanup");
        // setTimeout(connectWebSocket, 3000);
      }
      console.log("socketRef", socketRef);
    };
  }, []);
  const outStatsdisplayOrder = [
    "calls_made",
    "calls_answered",
    "response_level",
  ];

  const incStatsdisplayOrder = [
    "calls_received",
    "calls_answered",
    "service_level",
  ];
  return (
    <div data-testid="wallboard" className="space-y-3">
      {/*Top Bar  */}

      <div className="ml-[-12px] h-full">
        <div className="pt-3 px-3">
          <div className="bg-[white] h-[60px]  rounded-md">
            <div className="flex justify-between h-full px-4">
              <div className=" font-bold flex items-center text-[16px] -mt-0.5">
                {"Wallboard"}
              </div>
              {/* <div className="smallPicker text-right w-[200px] mt-0.5">
                <ComponentMap data={wallBoardData} setData={setWallBoardData} />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/*  */}

      {/* Body */}
      <div className="h-[calc(100vh-195px)] w-[calc(100vw-126px)] rounded-md gap-10 overflow-y-auto overflow-x-hidden">
        <Backdrop className="!z-[99999999999]" open={loader}>
          <Lottiefy loop={true} json={loaderLottie} height={100} width={100} />
        </Backdrop>
        <div className="flex gap-2 w-[100%] ">
          <div className="w-full h-full bg-[#fff] rounded-md p-3 space-y-8">
            <div className="flex justify-between">
              <p className="font-bold text-[24px]"> Incoming Statistics</p>
              {/* <p className="cursor-pointer">
                <DragIcon2 />
              </p> */}
            </div>
            {/* Boxes */}
            <div className="grid grid-cols-3 gap-2">
              {incStatsdisplayOrder
                .filter((key: any) => key in incStatsData) // Ensure the key exists in the data
                .map((key: any, index: number) => {
                  const formattedKey = key
                    .replace(/_/g, " ")
                    .split(" ")
                    .map(
                      (word: any) =>
                        word.charAt(0).toUpperCase() + word.slice(1)
                    )
                    .join(" ");

                  return (
                    <div
                      className={`${
                        index === 0
                          ? "bg-[#FFDBB2]"
                          : index === 1
                          ? "bg-[#D3D8FF]"
                          : "bg-[#FFF2CC]"
                      }  rounded-lg text-left p-2 px-3`}
                    >
                      <p className="font-bold text-[36px]">
                        {incStatsData[key]}
                        {key === "service_level" && "%"}
                      </p>
                      <p className="text-[24px]">{formattedKey}</p>
                    </div>
                  );
                })}
            </div>
            {/*  */}

            <Table
              tableTitles={incStatisticsTableTitles}
              tableData={incStatisticsTableData}
              width={"w-[100%]"}
              scrollHeight={"max-h-[320px] overflow-y-auto"}
            />
          </div>
          <div className="w-full bg-[#ffffff] rounded-md p-3 space-y-8">
            <div className="flex justify-between">
              <p className="font-bold text-[24px]"> Outgoing Statistics</p>
              {/* <p className="cursor-pointer">
                <DragIcon2 />
              </p> */}
            </div>
            {/* Boxes */}
            <div className="grid grid-cols-3 gap-2">
              {outStatsdisplayOrder
                .filter((key: any) => key in outStatsData) // Ensure the key exists in the data
                .map((key: any, index: number) => {
                  const formattedKey = key
                    .replace(/_/g, " ")
                    .split(" ")
                    .map(
                      (word: any) =>
                        word.charAt(0).toUpperCase() + word.slice(1)
                    )
                    .join(" ");

                  return (
                    <div
                      className={`${
                        index === 0
                          ? "bg-[#FFDBB2]"
                          : index === 1
                          ? "bg-[#D3D8FF]"
                          : "bg-[#FFF2CC]"
                      } rounded-lg text-left p-2 px-3`}
                    >
                      <p className="font-bold text-[36px]">
                        {outStatsData[key]}
                      </p>
                      <p className="text-[24px]">{formattedKey}</p>
                    </div>
                  );
                })}

              {/* <div className=" bg-[#D3D8FF] rounded-lg text-left p-2 px-3">
                <p className="font-bold text-[36px]">1400</p>
                <p className="text-[24px]">Calls Answered</p>
              </div>
              <div className=" bg-[#FFF2CC] rounded-lg text-left p-2 px-3">
                <p className="font-bold text-[36px]">99.99%</p>
                <p className="text-[24px]">Response Level</p>
              </div> */}
            </div>
            {/*  */}
            <Table
              tableTitles={outStatisticsTableTitles}
              tableData={outStatisticsTableData}
              width={"w-[100%]"}
              scrollHeight={"max-h-[320px] overflow-y-auto"}
            />
          </div>
        </div>
        <div className="bg-[#ffffff] mt-2 w-[100%] rounded-md h-[calc(100vh-527px)]  p-3">
          <div className="flex justify-between">
            <p className="font-bold text-[24px]"> Users</p>
            {/* <p className="cursor-pointer">
              <DragIcon2 />
            </p> */}
          </div>
          <Table
            tableTitles={usersTableTitles}
            tableData={usersTableData}
            setTableData={setUsersTableData}
            width={"w-[calc(100vw-145px)]"}
            scrollHeight={"max-h-[320px] overflow-y-auto"}
          />
        </div>
        {/* <div className="bg-[#ffffff] w-[100%] h-[calc(100vh-527px)] rounded-md mt-2 p-3">
          <div className="flex justify-between">
            <p className="font-bold text-[24px]"> Support</p>
            <p className="cursor-pointer">
              <DragIcon2 />
            </p>
          </div>
          <Table
            tableTitles={tableTitles2}
            tableData={tableData2}
            width={"w-[calc(100vw-145px)]"}
            scrollHeight={"max-h-[320px] overflow-y-auto"}
          />
        </div> */}
      </div>
      {/*  */}
    </div>
  );
}

export default Wallboard;
