import React, { useContext, useState } from "react";
import { useAuth } from "../authv2/authClient";
import ComponentMap from "../../atom/componentmap";
import ThemedButton from "../../atom/ThemedButton/themedButton";
import { activityState } from "../../config/constants";
import Logo from "../../utils/images/logo.png";
import Vaspianlogo from "../../utils/images/vaspianlogo.png";

import LockOpenIcon from "@mui/icons-material/LockOpen";
import Loader from "../loader";
import FadeIn from "react-fade-in";
const Loginv2 = (props: any) => {
  const auth = useAuth();
  const [loader, setLoader] = useState<boolean>(false);
  const [data, setData] = React.useState<any>([
    {
      name: "username",
      title: "Email",
      focus: true,
      required: true,
      autoFocus: true,
      value: "",
      errormessage: "Email is required",
    },
    {
      name: "password",
      title: "Password",
      type: "password",
      //   autoFocus: true,
      required: true,
      value: "",
      errormessage: "Minimum 3 characters are required",
    },
  ]);
  const handleLogin = (payload: { username: string; password: string }) => {
    setLoader(true);
    auth.tryLogin(payload, (isAuthenticated: any) => {
      setLoader(false);
    });
  };

  return (
    <div className="w-full h-full flex bg-ternary">
      <div className="w-1/2 max-md:hidden p-24 relative md:flex flex-auto items-center justify-center h-full bg-[#252861]">
        <svg
          width="312"
          height="202"
          viewBox="0 0 312 202"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute top-0 -right-24"
        >
          <circle
            opacity="0.5"
            cx="156"
            cy="46"
            r="136.5"
            stroke="#98A6DD"
            stroke-width="39"
          />
        </svg>
        {/* bottom left circle */}

        <svg
          width="192"
          height="193"
          viewBox="0 0 192 193"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute bottom-0 -left-16"
        >
          <circle
            opacity="0.5"
            cx="46"
            cy="146"
            r="126.5"
            stroke="#98A6DD"
            stroke-width="39"
          />
        </svg>
        <div className="z-10 relative w-full max-w-2xl text-[#FFFFFF]">
          <FadeIn>
            <div className="text-4xl font-bold leading-none text-white">
              {/*
               */}
              <img src={Vaspianlogo} height={400} width={400}></img>
            </div>
            <div className="mt-10 text-[44px] font-light leading-[3rem] text-[#FEBA12]">
              Your Epic Choice Among
              <br /> Telecom Service Providers
            </div>
          </FadeIn>
        </div>
      </div>

      <div className="w-1/2 max-md:w-full text-xl relative sm:flex items-center justify-center max-md:justify-center bg-[#ffffff]">
        <div className=" right-0 w-full md:w-[520px] max-sm:p-10 p-20 ">
          <div className="  max-w-full  w-full">
            <div className=" max-w-full w-full mx-auto sm:mx-0">
              <FadeIn>
                <div className="text-[40px] font-bold leading-none text-[#000C39] text-center">
                  Sign in
                </div>
                <div
                  className={`flex  flex-col justify-center  w-full mt-2  h-[200px] transition-height duration-500 ease-in-out smallPicker`}
                >
                  <ComponentMap data={data} setData={setData} />
                </div>
                <div className="flex justify-between p-2 mt-2 ">
                  <div className="pt-3">
                    {/* <span className="cursor-pointer">Don’t have an account?</span> */}
                  </div>
                  <div>
                    <ThemedButton
                      children={"Login"}
                      loading={loader}
                      click={handleLogin}
                      valiData={data}
                      change={setData}
                      theme={"primary"}
                    />
                  </div>
                </div>
              </FadeIn>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loginv2;
