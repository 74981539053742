import { useEffect, useState, useRef } from "react";
import { callTranscript } from "../../services/api";
import Loader from "../loader";
import WaveSurfer from "wavesurfer.js";
// import data from "./tempData";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import Tooltip from "@mui/material/Tooltip";
import { Filter } from "bad-words";
import store from "../../toolkit/store";
import * as loaderLottie from "../../utils/lottie/noData.json";

import {
  DownArrow,
  DownloadIcon,
  UpArrow,
} from "../../utils/icons/defaultIcons";
import SearchIcon from "@mui/icons-material/Search";
import { getENVData } from "../../config/environment";
import { useSelector } from "react-redux";
import Lottiefy from "../../atom/lottie/lottie";

const TransacribeModal = (props: any) => {
  const filter = new Filter({ placeHolder: "*" });
  const token = useSelector((state: any) => state.auth.token);

  const [currentIndex, setCurrentIndex] = useState(0);
  const highlightsRef = useRef<NodeListOf<HTMLElement> | null>(null);
  const { transcribe } = props;
  let container: any;
  let userScrolling: boolean = false;
  const [diarisation, setDiarisation] = useState<any>([]);
  const [transcript, setTranscript] = useState<any>({});
  const [searchText, setSearchText] = useState("");
  const [audioUrl, setAudioUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<any>(0);
  const [transcriptLoading, setTranscriptLoading] = useState(true);
  const waveformRef = useRef<any>(null);
  const wavesurferRef = useRef<any>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const transcriptRefs = useRef<any>([]); // To store refs for each transcript item
  const transcriptContainerRef = useRef<HTMLDivElement>(null);
  const scrollTimeoutRef = useRef<NodeJS.Timeout | null>(null); // Scroll timeout ref
  const getId = () => {
    const state: any = store.getState();
    if (state.Simulation.simulate?.uuid) {
      return state.Simulation.simulate.uuid;
    } else {
      return state.Permissions.accountId;
    }
  };
  const togglePlay = () => {
    if (wavesurferRef.current) {
      wavesurferRef.current.playPause();
      setIsPlaying(!isPlaying);
    }
  };

  const seekForward = () => {
    if (wavesurferRef.current) {
      userScrolling = false;
      wavesurferRef.current.skipForward(10);
    }
  };

  const seekBackward = () => {
    if (wavesurferRef.current) {
      userScrolling = false;
      wavesurferRef.current.skipBackward(10);
    }
  };
  const playFromTime = (timeInSeconds: number) => {
    if (wavesurferRef.current) {
      userScrolling = false;
      const duration = wavesurferRef.current.getDuration(); // Get total duration
      const position = timeInSeconds / duration; // Calculate position (0 to 1)
      wavesurferRef.current.seekTo(position); // Seek to the position
      wavesurferRef.current.play(); // Play from the position
      setIsPlaying(true);
    }
  };
  const scrollToHighlight = (index: number) => {
    if (highlightsRef.current && highlightsRef.current[index]) {
      // Remove 'active' class from all highlights
      highlightsRef.current.forEach((el) => el.classList.remove("active"));

      // Add 'active' class to the current highlight
      const currentHighlight = highlightsRef.current[index];
      currentHighlight.classList.add("active");

      // Scroll to the current highlight
      currentHighlight.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  const handleNext = () => {
    if (
      highlightsRef.current &&
      currentIndex < highlightsRef.current.length - 1
    ) {
      setCurrentIndex((prev) => {
        const nextIndex = prev + 1;
        scrollToHighlight(nextIndex);
        return nextIndex;
      });
    }
  };
  const handlePrev = () => {
    if (highlightsRef.current && currentIndex > 0) {
      setCurrentIndex((prev) => {
        const prevIndex = prev - 1;
        scrollToHighlight(prevIndex);
        return prevIndex;
      });
    }
  };
  const handleSearch = (text: string) => {
    setSearchText(text);
    setTimeout(() => {
      highlightsRef.current = document.querySelectorAll("mark.highlight");
      if (highlightsRef.current?.length) {
        setSearchResults(highlightsRef.current.length);
        scrollToHighlight(0);
        setCurrentIndex(0);
        setSearchResults(true);
      } else {
        setSearchResults(0);
        setCurrentIndex(0);
        setSearchResults(false);
        scrollToHighlight(0);
      }
    }, 200);
  };
  const highlightText = (value: string): string => {
    if (!searchText) return value;
    const regex = new RegExp(`(${searchText})`, "gi");
    return value.replace(regex, `<mark class="highlight">$1</mark>`); // Wrap matched text with <mark> tag
  };
  const fetchAudio = async () => {
    try {
      setIsLoading(true);
      const src = transcribe.recordings_l[0].file_path.replace(
        "/opt/vaspian/data/recordings/",
        `${getENVData().baseURL}cr/tenants/${getId()}/call_recordings/download/`
      );
      const response: any = await fetch(src, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });
      // console.log("response", response);
      // const response = await fetch(
      //   "https://ncsapp.com/docs/f840cfe9-7a4f-4d16-9815-c831edf5f0c5/1734674324702/harry_show.mp3"
      // ); // Replace with your MP3 file's path
      // // const response = await fetch(
      // //   "https://ncsapp.com/docs/f840cfe9-7a4f-4d16-9815-c831edf5f0c5/1735192425315/diarization_test.wav"
      // // ); // Replace with your MP3 file's path
      if (!response.ok) {
        throw new Error("Failed to fetch audio file");
      }
      const blob = await response.blob();
      const url: any = URL.createObjectURL(blob);
      setAudioUrl(url);
      setIsLoading(false);
    } catch (error) {
      console.error("Error loading audio:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };
  function splitTextIntoArrays(text: any, duration: any, startTime: any) {
    const words = text.toString().split(/\s+/); // Split the text into words
    // console.log("###----- text", text);
    // console.log("###----- words", words);

    const totalWords = words.length;
    // console.log("###----- totalWords", totalWords);
    // console.log("###----- duration", duration);
    const chunkSize = Math.ceil(totalWords / (duration / 1000)); // Calculate the number of words per chunk
    // console.log("###----- chunkSize", chunkSize);

    const expTime = duration / (totalWords / chunkSize); // Calculate the expected time for each chunk;
    // console.log("###----- expTime", expTime);
    const result = [];
    for (let i = 0, j = 0; i < totalWords; i += chunkSize, j++) {
      const data = {
        chunk: words.slice(i, i + chunkSize).join(" "),
        start: expTime * j + startTime,
        totalWords,
        chunkSize,
        startTime,
        expTime,
        end: expTime * (j + 1) + startTime,
      };
      result.push(data); // Join words into a chunk with spaces
      // console.log("###----- data", data);
    }
    return result;
  }

  const TranscriptSegmentation = (data: any) => {
    let result: any = [...data];
    result.map((item: any, index: number) => {
      item.duration = item.end - item.start;
      const part = splitTextIntoArrays(
        filter.clean(item.text),
        item.duration,
        item.start
      );
      item.textParts = part;
    });
    console.log("result", result);
    return result;
  };
  const handleScroll = () => {
    userScrolling = true;
    if (scrollTimeoutRef.current) clearTimeout(scrollTimeoutRef.current);
    scrollTimeoutRef.current = setTimeout(() => {
      userScrolling = false;
    }, 2000);
  };
  const downloadTranscript = () => {
    if (diarisation.length > 0) {
      let summary = "";
      if (transcript.summary) {
        summary =
          `Summary\n` +
          `-----------------------------------------\n\n` +
          `${transcript.summary.replace(/#/g, "")}\n\n`;
      }
      let keywords = "";
      if (transcript.keywords) {
        keywords =
          `Keywords\n` +
          `-----------------------------------------\n\n` +
          `${transcript.keywords.map((item: any) => item.name).join(", ")}\n\n`;
      }
      const text =
        `\nCall details\n` +
        `-----------------------------------------\n\n` +
        `Calling number: ${transcribe.calling_number}\n` +
        `Dialed number: ${transcribe.dialed_number}\n` +
        `Extension: ${transcribe.orig_ext}\n\n` +
        `Start: ${transcribe.start_date + " " + transcribe.start_time}\n` +
        `End: ${transcribe.start_date + " " + transcribe.end_time}\n` +
        `Duration: ${transcribe.total_time}\n\n` +
        `${summary}` +
        `${keywords}` +
        "Transcript\n" +
        `-----------------------------------------\n\n` +
        diarisation
          .map((item: any) => {
            let text = "";
            text += item.speaker
              ? `Speaker ${item.speaker} (${formatTime(item.start / 1000)})\n`
              : "";
            text += filter.clean(item.text);
            return text;
          })
          .join("\n\n");
      const blob = new Blob([text], { type: "text/plain" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `Call Transcription ${transcribe.calling_number}-${
        transcribe.start_date + " " + transcribe.start_time
      }.txt`;
      a.click();
      URL.revokeObjectURL(url);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      if (audioUrl && waveformRef.current) {
        wavesurferRef.current = WaveSurfer.create({
          container: waveformRef.current,
          waveColor: "#9eac9e",
          progressColor: "rgb(254, 186, 18)",
          barHeight: 4, // Height multiplier for bars
          height: 80,
        });
        wavesurferRef.current.load(audioUrl);
        wavesurferRef.current.on("audioprocess", () => {
          const current = wavesurferRef.current.getCurrentTime();
          setCurrentTime(current);

          if (!userScrolling) {
            diarisation.forEach((item: any, index: number) => {
              if (current * 1000 >= item.start && current * 1000 <= item.end) {
                const ref = transcriptRefs.current[index];
                if (ref) {
                  ref.scrollIntoView({ behavior: "smooth", block: "center" });
                }
              }
            });
          }
        });
        wavesurferRef.current.on("ready", () => {
          setDuration(wavesurferRef.current.getDuration());
        });
        wavesurferRef.current.on("finish", () => {
          setIsPlaying(false); // Update UI to reflect stopped state
        });
      }
      let container = transcriptContainerRef.current;
      if (container) {
        container.addEventListener("scroll", handleScroll);
      }
    }, 1000);
    return () => {
      if (wavesurferRef.current) {
        wavesurferRef.current.destroy();
      }
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [audioUrl]);
  useEffect(() => {
    const payload = {
      file_path: transcribe.recordings_l[0].file_path.replace(
        "/opt/vaspian/data/recordings/",
        ""
      ),
    };

    setTranscriptLoading(true);
    callTranscript(payload).then((result: any) => {
      if (result.success) {
        setTranscript(result.data);
        setDiarisation(TranscriptSegmentation(result.data.transcript));
        setTranscriptLoading(false);
      } else {
        setTranscript("");
        setTranscriptLoading(false);
      }
    });
    // data.transcript.map((item: any) => {
    //   item.start = timeToSeconds(item.start);
    //   item.end = timeToSeconds(item.end);
    // });
    fetchAudio();
    console.log("transcribe", transcribe);
  }, []);

  return (
    <div
      className={`${
        transcriptLoading || transcript === ""
          ? "w-[400px] h-[200px]"
          : "w-[1000px]"
      } transition-all duration-500`}
    >
      {transcriptLoading ? (
        <div className="flex justify-center items-center  h-full w-full">
          <Loader />
        </div>
      ) : transcript === "" ? (
        <div className="flex -mt-6 justify-center items-center flex-col  h-full w-full">
          <Lottiefy loop={true} json={loaderLottie} height={100} width={100} />
          <div className="pt-4 pb-4">No transcript available</div>
        </div>
      ) : (
        <div className="">
          <div className="bg-[#FEF4E9] rounded-[5px] p-6 flex">
            <div className="pr-8 w-[70%]">
              {!transcriptLoading && transcript?.summary.length === 0 ? (
                <div className="mb-2 mr-2 inline-block bg-[#00000012] capitalize px-2 py-1 rounded-lg">
                  No summary available
                </div>
              ) : (
                <>
                  <div className="text-xl font-thik mb-2 ">Summary</div>
                  <div className="max-h-[100px] overflow-y-auto">
                    {transcript?.summary
                      ? transcript?.summary.replace(/\*/g, "").replace(/#/g, "")
                      : ""}
                  </div>
                </>
              )}
            </div>
            <div className="w-[30%]">
              {Array.isArray(transcript?.keywords) &&
              transcript?.keywords.length === 0 ? (
                <div className="mb-2 mr-2 inline-block bg-[#00000012] capitalize px-2 py-1 rounded-lg">
                  No keywords available
                </div>
              ) : (
                <>
                  <div className="text-xl font-thik mb-2 ">Keywords</div>
                  <div className="max-h-[100px] overflow-y-auto">
                    {transcript?.keywords?.map((item: any, index: number) => {
                      return (
                        <div
                          className="mb-2 mr-2 inline-block bg-[#00000012] capitalize px-2 py-1 rounded-lg"
                          key={index}
                        >
                          {item}
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
          {isLoading ? (
            <div className="border opacity-50 p-8 border-primary rounded-[5px] mt-4">
              <Loader />
            </div>
          ) : (
            <div className="border py-2 pb-4 px-2  border-primary rounded-[5px] mt-4">
              <div>
                <div ref={waveformRef} className="overflow-hidden"></div>
                <div className="flex items-center justify-center mt-2">
                  <div className="flex items-center">
                    <Tooltip title="Seek Backward">
                      <button
                        onClick={seekBackward}
                        className="ml-4 scale-150  hover:text-primary"
                      >
                        <KeyboardDoubleArrowLeftIcon />
                      </button>
                    </Tooltip>
                    <Tooltip title={isPlaying ? "Pause" : "Play"}>
                      <button
                        className="ml-4 cursor-pointer scale-150 hover:text-primary"
                        onClick={togglePlay}
                      >
                        {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                      </button>
                    </Tooltip>
                    <Tooltip title="Seek Forward">
                      <button
                        onClick={seekForward}
                        className="ml-4 scale-150  hover:text-primary"
                      >
                        <KeyboardDoubleArrowRightIcon />
                      </button>
                    </Tooltip>
                    <div className="ml-4 ">
                      {formatTime(currentTime)} / {formatTime(duration)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className=" pt-4 flex justify-between items-center">
            <div className="text-xl">Transcript</div>
            <div className="flex items-center">
              <div className="relative">
                <input
                  type="text"
                  autoFocus
                  value={searchText}
                  onChange={(e) => handleSearch(e.target.value)}
                  className="mr-6 w-[400px] border border-gray rounded-[5px] px-2 pr-[140px] py-1"
                  placeholder="Search"
                />
                <div className="absolute right-8 top-1 cursor-pointer">
                  {searchText === "" ? (
                    <SearchIcon
                      onClick={() => {
                        setSearchText("");
                      }}
                    />
                  ) : (
                    <div className="absolute flex right-0 top-0 text-[#1256FE] cursor-pointer ">
                      {!searchResults ? (
                        <div className="w-[100px] text-right text-[red] mr-2">
                          No results
                        </div>
                      ) : (
                        <div className="flex items-center">
                          <div className="w-[60px] text-right mr-2">
                            {currentIndex + 1} of{" "}
                            {highlightsRef?.current?.length
                              ? highlightsRef.current.length
                              : 0}
                          </div>
                          <button onClick={handlePrev}>
                            <UpArrow />
                          </button>
                          <button onClick={handleNext}>
                            <DownArrow />
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div
                className="text-[#1256FE] cursor-pointer flex items-center"
                onClick={() => downloadTranscript()}
              >
                <DownloadIcon fill="#1256FE" />
                <div className="ml-2">Download</div>
              </div>
            </div>
          </div>
          <div
            ref={transcriptContainerRef}
            className="border border-[#fed8af] rounded-[5px] p-4 mt-4 h-[calc(100vh-600px)] overflow-y-auto"
          >
            {diarisation.length === 0 ? (
              <div className="pt-16 text-center text-[#999]">
                No transcript available
              </div>
            ) : (
              diarisation.map((item: any, index: number) => {
                return (
                  <div
                    className="text-[16px] p-[4px]"
                    key={index}
                    ref={(el) => (transcriptRefs.current[index] = el)} // Assign ref to each transcript
                  >
                    <div
                      onClick={() => playFromTime(item.start / 1000)}
                      className={`${
                        currentTime * 1000 > item.start &&
                        currentTime * 1000 < item.end
                          ? "text-primary"
                          : ""
                      }  text-[#000C39]`}
                    >
                      {item.speaker
                        ? `Speaker ${item.speaker} (${formatTime(
                            item.start / 1000
                          )})`
                        : ""}{" "}
                    </div>
                    <div
                      onClick={() => playFromTime(item.start / 1000)}
                      className={`border w-[calc(100%-100px)]  text-[gray] italic border-none cursor-pointer hover:border-[#fed8af] ${
                        currentTime * 1000 > item.start &&
                        currentTime * 1000 < item.end
                          ? "text-primary"
                          : ""
                      }`}
                    >
                      "
                      {item.textParts.map((part: any, pIndex: number) => {
                        return (
                          <div
                            // onClick={(e) => playFromTime(part.start / 1000)}
                            key={index + "_" + pIndex}
                            className={`inline-block  ${
                              currentTime * 1000 > part.start &&
                              currentTime * 1000 < part.end
                                ? "bg-[#f7efe6] text-[#a37503]"
                                : ""
                            }`}
                          >
                            <div
                              className="inline-block"
                              dangerouslySetInnerHTML={{
                                __html: highlightText(part.chunk),
                              }}
                            ></div>
                            <>&nbsp;</>
                          </div>
                        );
                      })}
                      "
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TransacribeModal;
