import ToggleButton from "./toggleButton";
import FadeIn from "react-fade-in";
import ReactAudioPlayer from "react-audio-player";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  DeleteIcon,
  DownloadIcon,
  PlayButton,
  InfoIcon,
  SelectAllIcon,
  TableSortIconDownArrow,
} from "../utils/icons/defaultIcons";
import Notification from "../atom/Notification";

import { useEffect, useMemo, useRef, useState } from "react";
import { motion } from "framer-motion";
import { Tooltip } from "@mui/material";
import ThemedButton from "./ThemedButton/themedButton";
import * as downloadLottie from "../utils/lottie/downloadLottie.json";
import Lottiefy from "./lottie/lottie";
import CheckPermission from "../services/permission";
import { useDispatch, useSelector } from "react-redux";
import { activeIndex } from "../toolkit/reducers/tableButton1IndexSlice";
import axios from "axios";
import Loader from "../containers/loader";
import { Link } from "react-router-dom";
import { getENVData } from "../config/environment";
import store from "../toolkit/store";
import Checkbox from "./checkbox";
import { title } from "process";
import moment from "moment";
import { set } from "date-fns";
import CustomTooltip from "./customTooltip";
import DurationCounter from "./timeDuration";

function Table(props: any) {
  /* ### Tabletitles ---> columns 
       ### In table titles include key!!!
    */
  const {
    rowColor,
    tableTitles,
    setTableTitles,
    handleActions,
    tableData,
    setTableData,
    icons,
    width,
    audioPlayer,
    scrollHeight,
    onHandleSort,
    actions = false,
    permission = "",
    loadingAction,
    resultNotFound,
    onhandleStateChange,
    actionHandler,
    setPlayerModal,
    setPlayerTableData,
    resultNotFound_Message = "",
  } = props;

  const [openPlayer, setOpenPlayer] = useState<number>(-1);
  const [selectedIndex, setSelectedIndex] = useState<any>(-1);
  const [checkboxClick, setCheckboxClick] = useState<boolean>(false);
  const token = useSelector((state: any) => state.auth.token);

  const dispatch = useDispatch();
  const getToken = () => {
    const currentAccount: any = window.localStorage.getItem("currentAccount");
    if (currentAccount) {
      const data: any = JSON.parse(currentAccount);
      const value = data.telco_id ? data?.telco_id : data?.tenant_id;
      return value;
    } else return "";
  };
  const getId = () => {
    const state: any = store.getState();
    if (state.Simulation.simulate?.uuid) {
      return state.Simulation.simulate.uuid;
    } else {
      return state.Permissions.accountId;
    }
  };
  const handleChecked = (
    nodes: any,
    index: number,
    name: string,
    field: string
  ) => {
    let copyData = [...tableData];
    const keys = Object.keys(nodes);

    keys.forEach((node: any) => {
      if (node === name) {
        nodes[node] = !nodes[node];
        copyData[index] = nodes;
        setTableData(copyData);
      }
    });
  };

  const onCheckboxClick = (action: string) => {
    if (action === "clicked") {
      setCheckboxClick(true);
    } else setCheckboxClick(false);
  };
  const handleColumnToggleButton = (node: any, index: number) => {
    let copyData = [...tableTitles];
    copyData[index].toggleButton = !copyData[index].toggleButton;
    setTableTitles(copyData);
  };
  const onMultiplePlayButton = (node: any, index: number) => {
    setPlayerModal(true);
    var data = [...tableData];
    data.map((node: any) => {
      node.playingURL = "";
      return node;
    });
    setTableData(data);
    setPlayerTableData(
      node.recordings_l.map((n: any) => {
        n.type = node?.type;
        return n;
      })
    );
  };

  const onHandlePlayButton = async (node: any, index: number) => {
    var data = [...tableData];
    data.map((node: any) => {
      node.playingURL = "";
      return node;
    });
    if (data[selectedIndex]) {
      data[selectedIndex].playingURL = "";
    }
    data[index].playingURL = "loading";
    setTableData(data);
    setSelectedIndex(index);
    let src;
    if (tableData[index]?.file_path || tableData[index]?.recordings_l[0]) {
      let path = tableData[index]?.file_path
        ? tableData[index]?.file_path
        : tableData[index]?.recordings_l[0]?.file_path;
      if (node?.type === "call_recordings") {
        src = path.replace(
          "/opt/vaspian/data/recordings/",
          `${
            getENVData().baseURL
          }cr/tenants/${getId()}/call_recordings/download/`
        );
      } else if (node?.type === "legacy_call_recordings") {
        src = path.replace(
          "/vdata/shared/recordings/",
          `${
            getENVData().baseURL
          }cr/tenants/${getId()}/call_recordings/download/legacy/`
        );
      }
      fetch(src, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.blob())
        .then((response) => {
          handlePlayer(response, index);
        })
        .catch((err) => {
          var data = [...tableData];
          data[index].playingURL = "loading";
          setTableData(data);
        });
    } else {
      data[index].playingURL = "";
      Notification("Failed", "Oops! Recording not Found", "danger");
    }
  };
  const handlePlayer = async (response: any, index: number) => {
    var data = [...tableData];
    const url = URL.createObjectURL(response);
    data[index].playingURL = url;

    setTableData(data);
    setTimeout(() => {
      setOpenPlayer(index);
    }, 1000);
  };

  const handleCheckboxChange = (index: number) => {
    let dummyData = [...tableData];
    dummyData[index].checked = !dummyData[index].checked;
    setTableData(dummyData);
  };

  const onHandleSelectAll = (x: string) => {
    let dummyData = [...tableData];
    const check = dummyData.every((node) => node.checked);
    dummyData.forEach((node: any, index: number) => {
      if (x === "unselect") node.checked = false;
      else if (x === "selectall") node.checked = true;
    });
    setTableData(dummyData);
  };

  const onHandleManage = (text: string, index: number) => {
    // setManageState(text);
    dispatch(activeIndex(index));
    onhandleStateChange(text, index);
  };
  const activeIndex1 = useSelector((state: any) => state.button1Index.index);
  const onHandleDelete = () => {};
  const handlePlayerClose = (index: number) => {
    var data = [...tableData];
    data[index].playingURL = "";
    setTableData(data);
    setOpenPlayer(-1);
  };

  const onManageSort = (node: any) => {
    var data = [...tableTitles];
    var sortOrder = "desc";
    data
      .filter((n) => n?.sort)
      .map((n: any) => {
        if (n.key === node.key) {
          sortOrder =
            n.sort.direction === "asc"
              ? n.sort.active
                ? "desc"
                : "asc"
              : "asc";
          n.sort.direction = sortOrder;
          n.sort.active = true;
        } else {
          n.sort.active = false;
          n.sort.direction = "asc";
        }
      });
    setTableTitles(data);
    onHandleSort(node, sortOrder.toUpperCase());
  };
  useMemo(() => {
    setOpenPlayer(-1);
  }, [tableData]);
  // const [ResizeWidth, handleResize] = useState<number>(200);
  // const [resizingColumnIndex, _setResizingColumnIndex] = useState<number>();
  // const resizingColumnIndexRef = useRef(resizingColumnIndex);

  // const setResizingColumnIndex = (index: number | undefined) => {
  //   console.log("indexindexindexindexindex",index)
  //     resizingColumnIndexRef.current = index;
  //     _setResizingColumnIndex(index);
  // };

  // const resizeListener = (e: MouseEvent) => {
  //   console.log("qqqqqqqqqqqqqq",resizingColumnIndexRef, e.movementX)
  //   // if (resizingColumnIndexRef.current) {
  //     handleResize(ResizeWidth + e.movementX);
  //   // }
  //   window.onmouseup = (e: MouseEvent) => {
  //     window.removeEventListener("mousemove", resizeListener);
  //     setResizingColumnIndex(undefined);
  //   };
  // };
  const handleDownload = () => {
    actionHandler("downloadSelected", {});
  };
  const handleActionButton = (action: string, node: any, index: number) => {
    dispatch(activeIndex(index));
    actionHandler(action, node);
  };
  return (
    <div
      data-testid="table"
      className={`mt-6 table-fix-head block  ${
        width
          ? width
          : "xl:w-[calc(100vw-126px)] w-full min-w-[500px] overflow-auto"
      } ${scrollHeight ? scrollHeight : "max-h-[700px] overflow-y-auto"} px-2 `}
    >
      <table className="w-full">
        <thead className="text-xs border-b-2 border-b-[#d1d1d1]">
          <tr className="">
            {tableTitles
              ?.filter((node: any) => !node?.hidden)
              ?.filter((node: any) =>
                node?.hide ? (node?.hide?.status === true ? true : false) : true
              )
              .map((node: any, index: number) => {
                return (
                  <th
                    key={index}
                    scope="col"
                    className={`${node?.class} py-2 relative px-2 text-left text-[#7c7c7c]  text-[15px] `}
                  >
                    {node?.type === "checkbox" ? (
                      <input
                        type="checkbox"
                        id={`checkbox_all`}
                        className={`w-4 h-4  rounded-lg cursor-pointer accent-[#F78F1E]  ${
                          node?.classname && `${node?.classname} mr-2`
                        }`}
                        checked={node?.checked}
                        onChange={() => actionHandler("selectall", node)}
                      />
                    ) : node?.type === "toggle" && node?.title !== "" ? (
                      <div
                        className={`${node?.classname} flex justify-end content-end items-end`}
                      >
                        <div>{node?.title}</div>
                        <ToggleButton
                          id={`toggle_all`}
                          click={() =>
                            actionHandler("toggleRowClick", node, node.key1)
                          }
                          checked={
                            tableData.length > 0
                              ? node.key1
                                ? tableData[0][node?.key1]
                                : false
                              : false
                          }
                        />
                      </div>
                    ) : node.type === "audioPlayer" ? (
                      node.checked ? (
                        <div
                          className={`${
                            node?.loading ? "animate-pulse" : ""
                          } -mr-8 svg-fill-primary-ulta`}
                        >
                          <ThemedButton
                            children={
                              node?.loading ? "Downloading" : "Download"
                            }
                            icon="download"
                            theme={"primary"}
                            click={handleDownload}
                          />
                        </div>
                      ) : (
                        <div className="py-[18px]">{node.title}</div>
                      )
                    ) : node.type === "ActionButton" ? (
                      <div className="py-[18px]">{node?.title}</div>
                    ) : node.tooltip ? (
                      <div className="cursor-pointer">
                        <CustomTooltip title={node?.tooltip ?? ""}>
                          {node.title}
                        </CustomTooltip>
                      </div>
                    ) : (
                      // <div
                      //   className={`hover:${node.tooltip} cursor-pointer z-50`}
                      // >
                      //   {node?.title}
                      // </div>
                      <div className="flex content-center justify-between">
                        <div
                          className="cursor-pointer"
                          onClick={() => onManageSort(node)}
                        >
                          {node?.title}
                        </div>
                        <div className=" bg-[white]  right-2 top-6 ">
                          {node?.sort && (
                            <div
                              className=" relative inline-block pl-2 h-6 w-6  cursor-pointer"
                              onClick={() => onManageSort(node)}
                            >
                              <div
                                className={`absolute  left-[-10px] -top-[4px] `}
                              >
                                {node?.sort.direction === "asc" ? (
                                  <KeyboardArrowDownIcon
                                    className={`${
                                      node?.sort.active
                                        ? "!text-primary"
                                        : "!text-[#222]"
                                    }`}
                                  />
                                ) : (
                                  <KeyboardArrowUpIcon
                                    className={`${
                                      node?.sort.active
                                        ? "!text-primary"
                                        : "!text-[#222]"
                                    }`}
                                  />
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </th>
                );
              })}
          </tr>
        </thead>
        <tbody className="w-full">
          {tableData?.map((node: any, index: number) => {
            const rowColorClass = index % 2 === 0 ? "bg-[#F2F4FB]" : "";
            return (
              <tr
                style={
                  rowColor !== undefined
                    ? { backgroundColor: node[rowColor] }
                    : {}
                }
                key={"sssss" + index}
                className={`${rowColorClass} border-b group border-b-[#d1d1d1] first-letter:cursor-pointer cursor-default hover:bg-[#FEF4E9] ${
                  node?.checked && "bg-[#FEF4E9]"
                }`}
              >
                {tableTitles
                  ?.filter((node: any) =>
                    node?.hide ? node?.hide.status === true : true
                  )
                  .map((column: any, colIndex: number) => {
                    return (
                      <td
                        id={`row${index}_col${colIndex}`}
                        key={colIndex}
                        className={`${
                          tableTitles.length - 1 === colIndex &&
                          !icons &&
                          audioPlayer === undefined &&
                          ""
                        } w-fit text-[#333] py-3 px-2 pr-4 ${
                          column?.class ? column?.class : ""
                        }`}
                      >
                        <>
                          {column.type === "boolean" ? (
                            <div>
                              {node[column.key] === true ? "YES" : "NO"}
                            </div>
                          ) : column.type === "toggle" ? (
                            <div
                              className={`flex justify-end items-end gap-1.5`}
                            >
                              {/* {console.log("fghm", node[column.key])} */}
                              {(node[column.key] === true ||
                                column?.toggleText === "Enabled") && (
                                <FadeIn>
                                  <div className="flex items-center justify-center space-x-2 w-[104%] rounded-lg bg-[#FBFBFF]">
                                    {column.shapeColour && (
                                      <div
                                        className={`${column.shapeColour} rounded-full h-2.5 w-2.5`}
                                      ></div>
                                    )}
                                    <div
                                      // className={`${
                                      //   column.textColour
                                      //     ? column.textColour
                                      //     : node[column.key] !== "enabled"
                                      //     ? "text-[#ABABAB]"
                                      //     : "text-[#04C48E]"
                                      // }`}
                                      className={`${
                                        node[column.key] === "disabled"
                                          ? "text-[#ABABAB]"
                                          : column.textColour
                                          ? column.textColour
                                          : "text-[#04C48E]"
                                      }`}
                                    >
                                      {node[column.key] === true ||
                                      node[column.key] === false
                                        ? column.toggleText
                                        : node[column.key] === "enabled"
                                        ? "Enabled"
                                        : "Disabled"}
                                    </div>
                                  </div>
                                </FadeIn>
                              )}
                              <ToggleButton
                                id={`toggle_${index}`}
                                click={() =>
                                  actionHandler(
                                    "toggleRowClick",
                                    node,
                                    column.key
                                  )
                                }
                                checked={
                                  node[column.key] === true ||
                                  node[column.key] === "enabled"
                                }
                              />
                              {/* {console.log("checked", node[column.key])} */}
                            </div>
                          ) : (column.type === "ActionButton" &&
                              column?.action?.title !== "") ||
                            (column?.action?.title === "" &&
                              node[column.key] &&
                              node[column.key] !== "") ? (
                            <div className="py-[2px] -m-3">
                              <ThemedButton
                                children={
                                  column?.action.title || node[column.key]
                                }
                                icon="custom"
                                theme="secondary"
                                click={() =>
                                  handleActionButton(
                                    column?.action.command,
                                    node,
                                    index
                                  )
                                }
                              />
                            </div>
                          ) : column?.type === "duration" ? (
                            <div id="durationId">
                              {node["answer_time"] !== undefined &&
                              node["answer_time"] !== "" ? (
                                <DurationCounter
                                  startTime={node["answer_time"]}
                                />
                              ) : (
                                "--"
                              )}
                            </div>
                          ) : column?.type === "time" ? (
                            <>
                              {node["answer_time"] !== undefined &&
                              node["answer_time"] !== ""
                                ? moment(new Date(node[column.key])).format(
                                    "hh:mm:ss"
                                  )
                                : node[column.key] !== ""
                                ? moment(
                                    new Date(node[column.key] * 1000)
                                  ).format("MM-DD-YYYY")
                                : "--"}
                            </>
                          ) : column.type === "action" ? (
                            <div
                              className={`${
                                column?.conditions &&
                                column?.conditions?.if?.value !==
                                  node[column.key] &&
                                node[column?.conditions?.else?.preTarget] !==
                                  undefined
                                  ? ""
                                  : "hidden"
                              } group-hover:block`}
                            >
                              {column?.conditions &&
                              column?.conditions?.if?.value !==
                                node[column.key] ? (
                                column?.conditions?.elseIf?.value ===
                                node[column?.conditions?.elseIf?.target] ? (
                                  <span className="inline-block px-2  -my-1 h-[20px] text-primary  transition duration-150 will-change-transform">
                                    <CustomTooltip
                                      title={
                                        column?.conditions?.elseIf?.tooltip ||
                                        ""
                                      }
                                    >
                                      <div className="h-[20px] px-2 flex justify-center items-center">
                                        {column?.conditions?.elseIf.icon}
                                      </div>
                                    </CustomTooltip>
                                  </span>
                                ) : (
                                  <div className="bg-[white] text-[14px] rounded-xl inline-block px-4">
                                    {node[
                                      column?.conditions?.else?.preTarget
                                    ] !== undefined
                                      ? Math.round(
                                          node[
                                            column?.conditions?.else?.preTarget
                                          ] === 100
                                            ? 99
                                            : node[
                                                column?.conditions?.else
                                                  ?.preTarget
                                              ]
                                        ) + "% "
                                      : ""}{" "}
                                    {column?.conditions?.else?.text}
                                  </div>
                                )
                              ) : (
                                column?.childrens.map((ico: any) => {
                                  return column?.loadingIcon &&
                                    loadingAction?.index === index &&
                                    loadingAction?.action === ico.action ? (
                                    <div className="inline-block px-2">
                                      {" "}
                                      <Loader size="sm" />
                                    </div>
                                  ) : ico.conditions &&
                                    ico.conditions?.if?.value ===
                                      node[ico?.conditions?.if?.target] ? (
                                    <span className="inline-block px-2  -my-1 h-[20px] text-primary  transition duration-150 will-change-transform">
                                      <div className="h-[20px] px-2 flex justify-center items-center">
                                        {ico?.conditions?.if.icon}
                                      </div>
                                    </span>
                                  ) : ico.conditions &&
                                    ico?.conditions?.elseIf?.value ===
                                      node[ico?.conditions?.elseIf?.target] ? (
                                    <span className="inline-block px-2  -my-1 h-[20px] text-primary  transition duration-150 will-change-transform">
                                      <div className="h-[20px] px-2 flex justify-center items-center">
                                        {ico?.conditions?.if.icon}
                                      </div>
                                    </span>
                                  ) : (
                                    <span className="inline-block  px-2 h-[20px] -my-1   transition duration-150 will-change-transform">
                                      <CustomTooltip
                                        title={
                                          ico?.tooltip ||
                                          ico?.conditions?.else?.tooltip ||
                                          ""
                                        }
                                      >
                                        <div
                                          onClick={() =>
                                            handleActions(
                                              ico.action,
                                              node,
                                              index
                                            )
                                          }
                                          className="h-[20px] cursor-pointer flex justify-center items-center ml-2"
                                        >
                                          {ico.icon}
                                        </div>
                                      </CustomTooltip>
                                    </span>
                                  );
                                })
                              )}
                            </div>
                          ) : column?.type === "bubbleStatus" ? (
                            <div
                              className={`text-transform: capitalize rounded-[50px] pl-3 gap-[2px] ${
                                node[column.key] === "enabled"
                                  ? "bg-[#FFFFFF] w-[80px] text-[#66800B]"
                                  : "bg-[#EBEBEB] w-[80px] text-[#ABABAB]"
                              }`}
                            >
                              {node[column.key]}
                            </div>
                          ) : column.type === "currentStatus" ? (
                            <div
                              className={`flex justify-center items-center rounded-[50px] w-[100px]  gap-x-2 ${
                                node.status.toLowerCase() === "available"
                                  ? "bg-[#fff]"
                                  : "bg-[#F7EFE7]"
                              }`}
                            >
                              <div
                                className={`rounded-full h-[7px] w-[7px] ${
                                  node.status.toLowerCase() === "available"
                                    ? "bg-[#879A39]"
                                    : "bg-[#D14D41]"
                                }`}
                              ></div>
                              <div
                                className={`${
                                  column?.class
                                } text-transform: capitalize   ${
                                  node.status.toLowerCase() === "available"
                                    ? "bg-[#fff] text-[#66800B]"
                                    : node.status.toLowerCase() === "busy"
                                    ? "bg-[#F7EFE7] text-[#AF3029]"
                                    : ""
                                }`}
                              >
                                {node?.status}
                              </div>
                            </div>
                          ) : (
                            <div
                              id={`${column.key}_${index}`}
                              className={`${
                                column.type === "link" &&
                                `text-[#1256FE] cursor-pointer `
                              }`}
                              onClick={() => {
                                column.type === "link" &&
                                  actionHandler("link", node, column.key);
                              }}
                            >
                              {column?.conditions
                                ? node[column?.conditions?.if?.target] ===
                                  column?.conditions?.if?.value
                                  ? node[column.key]
                                  : column?.conditions?.else?.icon
                                : node[column.key] === "undefined"
                                ? "--"
                                : node[column.key]}
                            </div>
                          )}
                          {column?.type === "checkbox" ? (
                            <input
                              type="checkbox"
                              id={`checkbox_${index}`}
                              className={`w-4 h-4  rounded-lg cursor-pointer accent-[#F78F1E] ${
                                column?.classname && column?.classname
                              }`}
                              checked={node?.checked ? node.checked : false}
                              onChange={() => actionHandler("change", node)}
                            />
                          ) : column?.type === "audioPlayer" ? (
                            <div className="relative  flex justify-end  items-end">
                              <div className="w-[16px]  relative ">
                                {openPlayer === index &&
                                node?.playingURL !== "loading" ? (
                                  <div className="flex justify-end relative  px-0 py-0 !w-[100%]">
                                    <div className="  mt-4 flex justify-end items-center cursor-pointer rounded-full px-2 py-[6px] !w-[100%]">
                                      <motion.div
                                        initial={{ x: 20 }}
                                        animate={{
                                          x: 0,
                                          transition: { delay: 0 },
                                        }}
                                        className="w-[110%]  h-6 flex justify-end items-end -mt-1"
                                      >
                                        <ReactAudioPlayer
                                          src={node?.playingURL}
                                          autoPlay={true}
                                          title={
                                            node?.file?.split("/")[
                                              node?.file?.split("/")?.length - 1
                                            ]
                                          }
                                          controls={true}
                                          loop={false}
                                          controlsList=""
                                          className="h-10 audio-player"
                                        />
                                        {!/Chrome/.test(
                                          navigator.userAgent
                                        ) && (
                                          <a
                                            href={node?.playingURL}
                                            download={
                                              node?.file?.split("/")[
                                                node?.file?.split("/")?.length -
                                                  1
                                              ]
                                            }
                                            className="mb-2.5  hover:transform hover:scale-110 transition duration-500 ease-in-out"
                                          >
                                            <DownloadIcon />
                                          </a>
                                        )}

                                        <div
                                          className=" mb-3 cursor-pointer hover:transform hover:scale-125 transition duration-500 ease-in-out"
                                          onClick={() =>
                                            handlePlayerClose(index)
                                          }
                                        >
                                          <svg
                                            width="20"
                                            height="16"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M19.2806 18.2194C19.3503 18.2891 19.4056 18.3718 19.4433 18.4629C19.481 18.5539 19.5004 18.6515 19.5004 18.7501C19.5004 18.8486 19.481 18.9462 19.4433 19.0372C19.4056 19.1283 19.3503 19.211 19.2806 19.2807C19.2109 19.3504 19.1282 19.4056 19.0372 19.4433C18.9461 19.4811 18.8485 19.5005 18.75 19.5005C18.6514 19.5005 18.5539 19.4811 18.4628 19.4433C18.3718 19.4056 18.289 19.3504 18.2194 19.2807L12 13.0604L5.78061 19.2807C5.63988 19.4214 5.44901 19.5005 5.24999 19.5005C5.05097 19.5005 4.8601 19.4214 4.71936 19.2807C4.57863 19.1399 4.49957 18.9491 4.49957 18.7501C4.49957 18.551 4.57863 18.3602 4.71936 18.2194L10.9397 12.0001L4.71936 5.78068C4.57863 5.63995 4.49957 5.44907 4.49957 5.25005C4.49957 5.05103 4.57863 4.86016 4.71936 4.71943C4.8601 4.5787 5.05097 4.49963 5.24999 4.49963C5.44901 4.49963 5.63988 4.5787 5.78061 4.71943L12 10.9397L18.2194 4.71943C18.3601 4.5787 18.551 4.49963 18.75 4.49963C18.949 4.49963 19.1399 4.5787 19.2806 4.71943C19.4213 4.86016 19.5004 5.05103 19.5004 5.25005C19.5004 5.44907 19.4213 5.63995 19.2806 5.78068L13.0603 12.0001L19.2806 18.2194Z"
                                              fill="#413E3E"
                                            />
                                          </svg>
                                        </div>
                                      </motion.div>
                                    </div>
                                  </div>
                                ) : node?.playingURL === "loading" ||
                                  (node?.playingURL &&
                                    node?.playingURL.length > 8) ? (
                                  <div className="flex justify-end relative  px-4 py-4">
                                    <div className="h-4 w-7  -mr-4 flex justify-end items-center cursor-pointer rounded-full px-2 ">
                                      <Loader size="sm" />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="flex justify-end  relative  px-4 py-4">
                                    <div
                                      className="h-4 w-7 flex   cursor-pointer rounded-full  px-2  hover:transform hover:scale-125 transition duration-500 ease-in-out"
                                      onClick={() => {
                                        if (node?.recordings_l?.length > 1) {
                                          onMultiplePlayButton(node, index);
                                        } else {
                                          onHandlePlayButton(node, index);
                                        }
                                      }}
                                    >
                                      <div>
                                        {" "}
                                        <PlayButton />
                                      </div>
                                      {/* <div className="ml-2 -mt-1">
                                        {node?.recordings_l?.length > 0 ? (
                                          <div>
                                            ({node?.recordings_l?.length})
                                          </div>
                                        ) : null}
                                      </div> */}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : column.type === "actionIcon" ? (
                            (column?.action.command,
                            (
                              <CustomTooltip
                                title={
                                  node?.disabled ? "" : column?.action.tooltip
                                  // ? column?.iconDisable
                                  //   ? ""
                                  //   : column?.action.tooltip
                                  // : ""
                                }
                              >
                                <button
                                  disabled={node?.disabled ?? false}
                                  className={` -m-3 float-right hidden group-hover:block ${
                                    column?.class
                                  } ${
                                    node?.disabled
                                      ? "cursor-not-allowed"
                                      : `${
                                          node?.monitor_state !== "off" &&
                                          column?.isActive &&
                                          "bg-[#0080004c] rounded-full p-[2px] scale-110"
                                        }
                             cursor-pointer transform hover:scale-110 transition duration-150 will-change-transform`
                                  } `}
                                  onClick={() => {
                                    !node?.disabled &&
                                      handleActionButton(
                                        column?.action.command,
                                        node,
                                        index
                                      );
                                  }}
                                  id={`${column?.action.title}_${index}`}
                                >
                                  {column?.icon}
                                </button>
                              </CustomTooltip>
                            ))
                          ) : column.type === "link" ? (
                            // actionHandler("link", node)
                            <div></div>
                          ) : (
                            ""
                          )}
                        </>
                      </td>
                    );
                  })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {resultNotFound ? (
        <div className="h-[100%] flex justify-center items-center">
          {resultNotFound_Message === "" ? (
            <b>No Results Found!</b>
          ) : (
            <b>{resultNotFound_Message}</b>
          )}
        </div>
      ) : null}
    </div>
  );
}

export default Table;
