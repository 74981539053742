import React, { useEffect, useRef, useState } from "react";
import SearchBar from "../../atom/search";
import {
  CheckIcon,
  CopyIcon,
  CsvIcon,
  ErrorIcon,
  EyeIcon,
  TableSortIconDownArrow,
  TickIcon,
  ZipCrunch,
} from "../../utils/icons/defaultIcons";
import FadeIn from "react-fade-in";
import ThemedButton from "../../atom/ThemedButton/themedButton";
import CheckPermission from "../../services/permission";
import Loader from "../loader";
import ToggleButton from "../../atom/toggleButton";
import CustomTooltip from "../../atom/customTooltip";
const ProgressCircle = ({ percentage }: { percentage: number }) => {
  return (
    <div className="relative flex items-center justify-center">
      {/* Circle */}
      <div
        className="h-[18px] w-[18px] border border-primary rounded-full"
        style={{
          background: `conic-gradient(var(--theme-primary) ${percentage}%, #fff 0)`,
        }}
      ></div>

      {/* Inner Text */}
      <div className="relative text-center">
        <span className="text-xs ml-2 text-[gray]">{percentage}%</span>
      </div>
    </div>
  );
};
function TableTopBar(props: any) {
  const {
    showDropdownValues,
    setHideDropdownValues,
    title,
    CSV = false,
    csvDownload = false,
    copy = false,
    search = true,
    button = false,
    toggle = false,
    toggleChecked,
    buttonText,
    setNewGroup,
    newGroup,
    CSVDropdown,
    setManageState,
    permission = "",
    searchtext,
    setSearchText,
    handleSearch,
    handleKeyPress,
    setHandleKeyPress,
    onhandleStateChange,
    handleCSVDownload,
    actionHandler,
    zipCrunch = false,
    zipCrunchDD = [],
    handleZipCrunch,
  } = props;

  const modalRef = useRef<HTMLInputElement>(null);
  const modalZipRef = useRef<HTMLInputElement>(null);
  const modalCSVRef = useRef<HTMLInputElement>(null);

  const [hideDropdown, setHideDropdown] = useState<boolean>(false);
  const [CSVDropdownList, SetCSVDropdownList] = useState<boolean>(false);
  const [hideCount, setHideCount] = useState<number>(0);
  const [hideZipCrunchDropdown, SethideZipCrunchDropdown] =
    useState<boolean>(false);
  const indeterminateRef = useRef<any>(null);
  const dropdownFilterUpdate = (node: any, index: number) => {
    let dummyData = [...showDropdownValues];
    var fIndex = dummyData.findIndex((itm: any) => node.key === itm.key);
    dummyData[fIndex].hide.status =
      node?.hide.default === true ? true : !dummyData[fIndex]?.hide.status;
    setHideDropdownValues(dummyData);
  };

  const handleActionButton = (action: string) => {
    console.log("first,", action);
    actionHandler(action);
  };

  const filterDropdown = (filter: string) => {
    if (filter === "showall") {
      // debugger
      let dummyData = [...showDropdownValues];
      var flag = true;
      var expLen = dummyData.filter(
        (node: any) => node?.hide?.status !== undefined
      ).length;
      var actLen = dummyData.filter(
        (node: any) => node?.hide?.status === true
      ).length;
      if (expLen === actLen) {
        flag = false;
      }
      dummyData
        .filter((node: any) => node?.hide)
        .forEach((node: any) => {
          node.hide.status = node.hide.default === true ? true : flag;
        });
      setHideDropdownValues(dummyData);
      setHideCount(0);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (modalRef.current && !modalRef.current.contains!(event.target)) {
        setHideDropdown(false);
      }
      if (modalZipRef.current && !modalZipRef.current.contains!(event.target)) {
        SethideZipCrunchDropdown(false);
      }
      if (modalCSVRef.current && !modalCSVRef.current.contains!(event.target)) {
        SetCSVDropdownList(false);
      }
    };

    // Attach the event listener to the document
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [hideDropdown]);

  useEffect(() => {
    // console.log("showDropdownValues", showDropdownValues)
    if (showDropdownValues !== undefined) {
      const len = showDropdownValues.filter(
        (item: any) => item?.hide?.status === true
      ).length;
      const total = showDropdownValues.filter((node: any) => node?.hide).length;
      setHideCount(len);
    }
  }, [showDropdownValues]);

  const onHandleClick = (text: string) => {
    if (text === "New Group") {
      setNewGroup(!newGroup);
    }
    if (text === "Provision new user") {
      setManageState("newUserProvision");
      onhandleStateChange("provisionuser");
    } else if (text === "Add Tenant") {
      onhandleStateChange("addTenant");
    }

    handleActionButton(text);
  };

  return (
    <div
      data-testid="tableTopBar"
      className={`flex px-4  text justify-between`}
    >
      <div className="  font-bold text-[18px] flex items-center">
        {title}
        {toggle && (
          <ToggleButton
            click={() => actionHandler("toggleclick")}
            checked={toggleChecked}
          />
        )}
      </div>

      {search === true && (
        <div className=" flex items-center justify-center sm:w-[400px] w-[300px] ">
          <SearchBar
            placeholder={"Search..."}
            searchText={searchtext}
            setSearchText={setSearchText}
            handleSearch={handleSearch}
            handleKeyPress={handleKeyPress}
            setHandleKeyPress={setHandleKeyPress}
          />
        </div>
      )}

      <div className="flex items-center justify-end ">
        {copy && (
          <div className="flex svg-fill-primary justify-center items-center gap-1.5 rounded-lg p-1 cursor-pointer hover:bg-ternary">
            <span>
              <CopyIcon />
            </span>
            <span>Copy</span>
          </div>
        )}
        {showDropdownValues !== undefined && (
          <div ref={modalRef} className="relative  ">
            <div
              id={"hide_columns_icon"}
              className="flex cursor-pointer items-center justify-evenly px-2 "
              onClick={() => {
                setHideDropdown(!hideDropdown);
              }}
            >
              <span
                className={`${
                  showDropdownValues?.filter(
                    (node: any) => node?.hide?.status === false
                  ).length !== 0
                    ? "svg-fill-primary"
                    : ""
                }`}
              >
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5 7H9.25C8.85218 7 8.47064 7.15804 8.18934 7.43934C7.90804 7.72064 7.75 8.10218 7.75 8.5V23.5C7.75 23.8978 7.90804 24.2794 8.18934 24.5607C8.47064 24.842 8.85218 25 9.25 25H11.5C11.8978 25 12.2794 24.842 12.5607 24.5607C12.842 24.2794 13 23.8978 13 23.5V8.5C13 8.10218 12.842 7.72064 12.5607 7.43934C12.2794 7.15804 11.8978 7 11.5 7ZM11.5 23.5H9.25V8.5H11.5V23.5ZM18.25 7H16C15.6022 7 15.2206 7.15804 14.9393 7.43934C14.658 7.72064 14.5 8.10218 14.5 8.5V23.5C14.5 23.8978 14.658 24.2794 14.9393 24.5607C15.2206 24.842 15.6022 25 16 25H18.25C18.6478 25 19.0294 24.842 19.3107 24.5607C19.592 24.2794 19.75 23.8978 19.75 23.5V8.5C19.75 8.10218 19.592 7.72064 19.3107 7.43934C19.0294 7.15804 18.6478 7 18.25 7ZM18.25 23.5H16V8.5H18.25V23.5ZM27.25 16C27.25 16.1989 27.171 16.3897 27.0303 16.5303C26.8897 16.671 26.6989 16.75 26.5 16.75H25V18.25C25 18.4489 24.921 18.6397 24.7803 18.7803C24.6397 18.921 24.4489 19 24.25 19C24.0511 19 23.8603 18.921 23.7197 18.7803C23.579 18.6397 23.5 18.4489 23.5 18.25V16.75H22C21.8011 16.75 21.6103 16.671 21.4697 16.5303C21.329 16.3897 21.25 16.1989 21.25 16C21.25 15.8011 21.329 15.6103 21.4697 15.4697C21.6103 15.329 21.8011 15.25 22 15.25H23.5V13.75C23.5 13.5511 23.579 13.3603 23.7197 13.2197C23.8603 13.079 24.0511 13 24.25 13C24.4489 13 24.6397 13.079 24.7803 13.2197C24.921 13.3603 25 13.5511 25 13.75V15.25H26.5C26.6989 15.25 26.8897 15.329 27.0303 15.4697C27.171 15.6103 27.25 15.8011 27.25 16Z"
                    fill="#413E3E"
                  />
                </svg>
              </span>
            </div>

            {/* {hideDropdown && */}
            {hideDropdown && (
              <div className="absolute w-[250px] h-fit z-50 top-12 -right-1 space-y-1  rounded-md border border-[#89898957] bg-[#FFFFFF]  px-1 py-2 ">
                <FadeIn>
                  <div
                    className="hover:bg-ternary rounded-sm py-2 flex justify-between cursor-pointer"
                    onClick={() => filterDropdown("showall")}
                  >
                    <span className="ml-2">All</span>
                    <span className="mr-2 ">
                      <input
                        checked={
                          showDropdownValues.filter(
                            (node: any) => node?.hide?.status !== undefined
                          ).length ===
                          showDropdownValues.filter(
                            (node: any) => node?.hide?.status === true
                          ).length
                        }
                        type="checkbox"
                        className="w-4 h-4  rounded-lg cursor-pointer accent-[#F78F1E]"
                      />
                    </span>
                  </div>
                  <hr className="mt-1" />
                  {showDropdownValues
                    ?.filter((node: any) => node?.hide)
                    .map((node: any, index: number) => {
                      return (
                        <>
                          <div
                            className="hover:bg-ternary rounded-sm py-2 flex justify-between cursor-pointer"
                            onClick={() => dropdownFilterUpdate(node, index)}
                          >
                            <span className="ml-2">{node.title}</span>
                            <span className="mr-2">
                              <input
                                type="checkbox"
                                disabled={node?.hide.default}
                                value=""
                                checked={node?.hide.status}
                                className="w-4 h-4  rounded-lg cursor-pointer accent-[#F78F1E]"
                              />
                            </span>
                          </div>
                        </>
                      );
                    })}
                </FadeIn>
              </div>
            )}
          </div>
        )}
        {CSV && (
          <div ref={modalCSVRef} className="relative">
            <div
              onClick={() =>
                CSVDropdown
                  ? SetCSVDropdownList(!CSVDropdownList)
                  : csvDownload
                  ? null
                  : handleCSVDownload()
              }
              className={`flex w-[24px]  ${
                csvDownload
                  ? "animate-pulse svg-fill-primary cursor-wait"
                  : " cursor-pointer"
              }   justify-center items-center gap-1.5 rounded-lg p-1 `}
            >
              <span className=" w-[24px]  flex content-center items-center justify-center  text-center">
                {csvDownload ? (
                  <Loader size="sm" />
                ) : (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.25 10.5001V19.5001C20.25 19.8979 20.092 20.2795 19.8107 20.5608C19.5294 20.8421 19.1478 21.0001 18.75 21.0001H5.25C4.85218 21.0001 4.47064 20.8421 4.18934 20.5608C3.90804 20.2795 3.75 19.8979 3.75 19.5001V10.5001C3.75 10.1023 3.90804 9.72075 4.18934 9.43944C4.47064 9.15814 4.85218 9.0001 5.25 9.0001H7.5C7.69891 9.0001 7.88968 9.07912 8.03033 9.21977C8.17098 9.36042 8.25 9.55119 8.25 9.7501C8.25 9.94901 8.17098 10.1398 8.03033 10.2804C7.88968 10.4211 7.69891 10.5001 7.5 10.5001H5.25V19.5001H18.75V10.5001H16.5C16.3011 10.5001 16.1103 10.4211 15.9697 10.2804C15.829 10.1398 15.75 9.94901 15.75 9.7501C15.75 9.55119 15.829 9.36042 15.9697 9.21977C16.1103 9.07912 16.3011 9.0001 16.5 9.0001H18.75C19.1478 9.0001 19.5294 9.15814 19.8107 9.43944C20.092 9.72075 20.25 10.1023 20.25 10.5001ZM8.78063 6.53073L11.25 4.06041V12.7501C11.25 12.949 11.329 13.1398 11.4697 13.2804C11.6103 13.4211 11.8011 13.5001 12 13.5001C12.1989 13.5001 12.3897 13.4211 12.5303 13.2804C12.671 13.1398 12.75 12.949 12.75 12.7501V4.06041L15.2194 6.53073C15.3601 6.67146 15.551 6.75052 15.75 6.75052C15.949 6.75052 16.1399 6.67146 16.2806 6.53073C16.4214 6.39 16.5004 6.19912 16.5004 6.0001C16.5004 5.80108 16.4214 5.61021 16.2806 5.46948L12.5306 1.71948C12.461 1.64974 12.3783 1.59443 12.2872 1.55668C12.1962 1.51894 12.0986 1.49951 12 1.49951C11.9014 1.49951 11.8038 1.51894 11.7128 1.55668C11.6217 1.59443 11.539 1.64974 11.4694 1.71948L7.71937 5.46948C7.57864 5.61021 7.49958 5.80108 7.49958 6.0001C7.49958 6.19912 7.57864 6.39 7.71938 6.53073C7.86011 6.67146 8.05098 6.75052 8.25 6.75052C8.44902 6.75052 8.63989 6.67146 8.78063 6.53073Z"
                      fill="#293241"
                    />
                  </svg>
                )}
              </span>
            </div>
            {CSVDropdownList && (
              <div className="absolute w-[250px] h-fit z-50 top-10 -right-1 space-y-1  rounded-md border border-[#89898957] bg-[#FFFFFF]  px-1 py-2 ">
                <FadeIn>
                  {CSVDropdown.map((node: any, index: number) => {
                    return (
                      <div
                        className="p-2 flex justify-between cursor-pointer  hover:bg-[#F5F5F5]"
                        key={index}
                        onClick={() => {
                          handleCSVDownload(node);
                        }}
                      >
                        {node.title}
                        {node.loading ? (
                          <div className="m-[-2px] mx-[-8px]">
                            <Loader size="sm" />
                          </div>
                        ) : (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M20.25 10.5001V19.5001C20.25 19.8979 20.092 20.2795 19.8107 20.5608C19.5294 20.8421 19.1478 21.0001 18.75 21.0001H5.25C4.85218 21.0001 4.47064 20.8421 4.18934 20.5608C3.90804 20.2795 3.75 19.8979 3.75 19.5001V10.5001C3.75 10.1023 3.90804 9.72075 4.18934 9.43944C4.47064 9.15814 4.85218 9.0001 5.25 9.0001H7.5C7.69891 9.0001 7.88968 9.07912 8.03033 9.21977C8.17098 9.36042 8.25 9.55119 8.25 9.7501C8.25 9.94901 8.17098 10.1398 8.03033 10.2804C7.88968 10.4211 7.69891 10.5001 7.5 10.5001H5.25V19.5001H18.75V10.5001H16.5C16.3011 10.5001 16.1103 10.4211 15.9697 10.2804C15.829 10.1398 15.75 9.94901 15.75 9.7501C15.75 9.55119 15.829 9.36042 15.9697 9.21977C16.1103 9.07912 16.3011 9.0001 16.5 9.0001H18.75C19.1478 9.0001 19.5294 9.15814 19.8107 9.43944C20.092 9.72075 20.25 10.1023 20.25 10.5001ZM8.78063 6.53073L11.25 4.06041V12.7501C11.25 12.949 11.329 13.1398 11.4697 13.2804C11.6103 13.4211 11.8011 13.5001 12 13.5001C12.1989 13.5001 12.3897 13.4211 12.5303 13.2804C12.671 13.1398 12.75 12.949 12.75 12.7501V4.06041L15.2194 6.53073C15.3601 6.67146 15.551 6.75052 15.75 6.75052C15.949 6.75052 16.1399 6.67146 16.2806 6.53073C16.4214 6.39 16.5004 6.19912 16.5004 6.0001C16.5004 5.80108 16.4214 5.61021 16.2806 5.46948L12.5306 1.71948C12.461 1.64974 12.3783 1.59443 12.2872 1.55668C12.1962 1.51894 12.0986 1.49951 12 1.49951C11.9014 1.49951 11.8038 1.51894 11.7128 1.55668C11.6217 1.59443 11.539 1.64974 11.4694 1.71948L7.71937 5.46948C7.57864 5.61021 7.49958 5.80108 7.49958 6.0001C7.49958 6.19912 7.57864 6.39 7.71938 6.53073C7.86011 6.67146 8.05098 6.75052 8.25 6.75052C8.44902 6.75052 8.63989 6.67146 8.78063 6.53073Z"
                              fill="#999"
                            />
                          </svg>
                        )}
                      </div>
                    );
                  })}
                </FadeIn>
              </div>
            )}
          </div>
        )}
        {zipCrunch && (
          <div ref={modalZipRef} className="relative  ">
            <div
              onClick={() => SethideZipCrunchDropdown(!hideZipCrunchDropdown)}
              className="ml-4 cursor-pointer"
            >
              <ZipCrunch />
            </div>
            {hideZipCrunchDropdown && (
              <div className="absolute w-[250px] h-fit z-50 top-11 -right-2 space-y-1  rounded-md border border-[#89898957] bg-[#FFFFFF]  px-1 py-2 ">
                <FadeIn>
                  <div className="border-b p-1 px-3 -mx-1 mb-2 border-[#89898957]">
                    Export by Carrier
                  </div>
                  {zipCrunchDD.filter(
                    (node: any) => node.status !== "completed"
                  ).length !== 0 && (
                    <>
                      <div
                        className="hover:bg-ternary rounded-sm py-2 flex justify-between cursor-pointer"
                        onClick={() => handleZipCrunch({}, "all")}
                      >
                        <span className="ml-2">All</span>
                        <span className="mr-2 ">
                          <input
                            checked={
                              zipCrunchDD.filter(
                                (n: any) => n.checked === false
                              ).length > 0
                                ? false
                                : true
                            }
                            type="checkbox"
                            className="w-4 h-4  rounded-lg cursor-pointer accent-[#F78F1E]"
                          />
                        </span>
                      </div>
                      <hr className="mt-1" />
                    </>
                  )}
                  {zipCrunchDD.map((node: any, index: number) => {
                    return (
                      <>
                        <div
                          key={index}
                          className={`${
                            node.status === "completed" ||
                            node.status === "progress"
                              ? "bg-primary-alpha-20 cursor-default"
                              : "hover:bg-ternary cursor-pointer"
                          }  rounded-sm py-2 flex justify-between `}
                          onClick={() => handleZipCrunch(node, "toggle")}
                        >
                          <span className="ml-2">{node.carrier}</span>
                          <span className="mr-2">
                            {node.status === "not_done" ? (
                              <input
                                type="checkbox"
                                value=""
                                checked={node?.checked || false}
                                className="w-4 h-4  rounded-lg cursor-pointer accent-primary"
                              />
                            ) : node.status === "completed" ? (
                              <div className="-mr-1">
                                <CheckIcon />
                              </div>
                            ) : node.status === "failed" ? (
                              <div className=" flex">
                                <CustomTooltip title="Error Occured, Try again">
                                  <div className="mr-2">
                                    <ErrorIcon />
                                  </div>
                                </CustomTooltip>
                                <input
                                  type="checkbox"
                                  value=""
                                  checked={node?.checked || false}
                                  className="w-4 h-4 mt-1 rounded-lg cursor-pointer accent-primary"
                                />
                              </div>
                            ) : (
                              <ProgressCircle
                                percentage={
                                  node?.percentage === 100
                                    ? 99
                                    : Math.round(node?.percentage) || 0
                                }
                              />
                            )}
                          </span>
                        </div>
                      </>
                    );
                  })}
                  <hr className="my-1 mb-2" />
                  {zipCrunchDD.filter(
                    (node: any) => node.status !== "completed"
                  ).length !== 0 ? (
                    <div className="text-center ">
                      {" "}
                      <ThemedButton
                        children="Export"
                        icon="custom"
                        theme="primary"
                        click={() =>
                          handleZipCrunch(zipCrunchDD, "ZipCrunchSubmit")
                        }
                      />
                    </div>
                  ) : (
                    <div className="text-[#aaa] text-center text-sm p-2">
                      All carriers are exported Successfully
                    </div>
                  )}
                </FadeIn>
              </div>
            )}
          </div>
        )}
      </div>
      <CheckPermission keyParam={permission}>
        {button && (
          <div className="text-right" id={`${buttonText}`}>
            <ThemedButton
              children={buttonText}
              icon="custom"
              theme="primary"
              click={() => onHandleClick(buttonText)}
            />
          </div>
        )}
      </CheckPermission>
    </div>
  );
}

export default TableTopBar;
