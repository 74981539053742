import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const CheckPermission = (props: { children: any; keyParam: string }) => {
  const [permit, setPermit] = useState(-1);
  const { children, keyParam } = props;
  const permissions = useSelector(
    (state: any) => state.Permissions.permissions
  );
  const checkUserPermission = () => {
    console.log("node1");

    var sprd = keyParam?.split(".");
    const filter = permissions[sprd[0]]?.find((node: any) => {
      return node === keyParam;
    });
    if (filter) {
      setPermit(1);
    } else {
      setPermit(0);
    }
  };
  useEffect(() => {
    checkUserPermission();
  }, [permissions]);
  return <>{permit === 1 ? children : ""}</>;
};
export default CheckPermission;
