import { useState } from "react";
import Modal from "../../../atom/modal";
import ThemedButton from "../../../atom/ThemedButton/themedButton";
import { DeleteIcon } from "../../../utils/icons/defaultIcons";
import { deleteSmsUser } from "../../../services/api";
import Notification from "../../../atom/Notification";
import Lottiefy from "../../../atom/lottie/lottie";

function DeleteSmsUserModal(props: any) {
  const [buttonLoader, setButtonLoader] = useState<boolean>(false);
  const [deleteUser, setDeleteUser] = useState<boolean>(false);
  const { setDeleteUserModal, selectedRowData, setPageLoader } = props;

  const handleDelete = () => {
    setButtonLoader(true);
    const payload: any = {
      sms_did: selectedRowData?.sms_did,
      sms_did_id: selectedRowData?.sms_did_id,
    };
    deleteSmsUser(selectedRowData?.uuid, payload).then((result: any) => {
      if (!result?.success) {
        Notification("Failed", result?.message, "danger");
        setButtonLoader(false);
      } else {
        setButtonLoader(false);
        setDeleteUser(true);
        setPageLoader(true);
        setDeleteUserModal(false);
        Notification("Success", "SMS user deleted successfully", "success");
      }
    });
  };
  return (
    <Modal
      title={"Delete"}
      icon={<DeleteIcon />}
      closeEvent={() => setDeleteUserModal(false)}
    >
      <div className={`text-[18px]  `}>
        Are you sure you want to delete
        <b>
          {""} {selectedRowData?.first_name} {selectedRowData?.last_name} (ext:{" "}
          {selectedRowData?.extension}){" "}
        </b>{" "}
        ?
        <div className="text-right w-full mt-10">
          <ThemedButton
            children={"Cancel"}
            icon="custom"
            theme="secondary"
            click={() => setDeleteUserModal(false)}
          />
          <ThemedButton
            children={"Delete"}
            icon="custom"
            theme="primary"
            click={handleDelete}
            loading={buttonLoader}
          />
        </div>
      </div>
    </Modal>
  );
}

export default DeleteSmsUserModal;
